.card {
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    padding: 25px;
    background-color: white;
}

.iconFrame {
    padding: 0.5em;
    border-radius: 500px;
}
