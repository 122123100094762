@charset "UTF-8";

body {
    margin: 0 !important;
    font-family: 'Euclid Circular B', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    letter-spacing: -0.5px;
    color: #101840;
    background-color: #f9fafc;
}

:root {
    --header-height: 4.5rem;
    --primary-color: #3366ff;
    --primary-color-hover: rgba(51, 102, 255, 0.7);
}

.fullHeightWithNav {
    height: calc(100vh - var(--header-height));
}

/* Buttons Bootstrap Override */

a {
    color: var(--primary-color);
    text-decoration: none;
}
a:hover {
    color: var(--primary-color-hover) !important;
}

button:focus:not(:focus-visible) {
    outline: 0 !important;
}

/* Brand */

.brandText {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -1.5px;
}

.topSpacing {
    margin-top: 11vh;
}

@media screen and (max-height: 700px) {
    .topSpacing {
        margin-top: 7vh;
    }
}
