.step {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    border-color: #d8dae5;
    border-style: solid;
    border-width: 2px;
    background-color: none;

    -webkit-transition: background-color border-color 0.3s ease;
    -moz-transition: background-color border-color 0.3s ease;
    -ms-transition: background-color border-color 0.3s ease;
    -o-transition: background-color border-color 0.3s ease;
    transition: background-color border-color 0.3s ease;
}

.complete {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.link1 {
    border-bottom: 2px solid var(--primary-color);
    width: 30px;
}

.link2 {
    border-bottom: 2px solid #d8dae5;
    width: 30px;
}

/* .step::before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    right: 0;
    z-index: 2;
} */

/* .step::after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color border-color: #7d7d7d;
    left: -50%;
    z-index: -1;
} */

/* .step::after {
    left: -50px;
} */
