.sidebar {
    width: 300px;
    background-color: black;
    height: 100%;
    display: flex;
    min-height: calc(100vh - var(--header-height));
    flex-direction: column;
    position: sticky;
    top: var(--header-height);
}

.sidebarBrand {
    bottom: 0;
    position: absolute;
    margin-left: 25px;
    margin-bottom: 20px;
}

.avatarGroup {
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 20px;
}

.avatarText {
    font-weight: 900 !important ;
}

.avatarWrapper {
    width: auto !important;
    height: auto;
}

.sidebarItem {
    border-radius: 0 10px 10px 0;
    overflow: hidden !important;

    -webkit-transition: background-color 0.1s ease;
    -moz-transition: background-color 0.1s ease;
    -ms-transition: background-color 0.1s ease;
    -o-transition: background-color 0.1s ease;
    transition: background-color 0.1s ease;
}

.sidebarItemContent {
    width: 100%;
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-right: 20px;
}

.sidebarItem:hover {
    background-color: rgba(249, 250, 252, 0.15);
}

.sidebarItemSide {
    border-right: 6px solid #3366ff;
    height: 100%;
    right: 0;
}

.sidebarItemActive {
    background-color: rgba(249, 250, 252, 0.1);
}
