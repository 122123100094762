.mainNav {
    height: var(--header-height);
    background-color: #000;
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
    position: sticky;
    top: 0;
}

.brandText {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    letter-spacing: -1.5px;
}

.logoutButton {
    background-color: rgba(216, 218, 229, 0);
    color: #d8dae5;
    border: none;
}

.logoutButton:hover {
    background-color: rgba(216, 218, 229, 0.2) !important;
    border: none !important;
}
